import React from 'react';

import Author from './base'
import messages from '../../../data/messages/en';

export default (props) => {
  const description = `Computer science enthusiastic. Passionate about building distributed systems, multicultural teams and innovative products. Blockchain & Cloud computing experience. Management and leadership skills.

`;
  const available = 'Available for hiring';
  const url = "https://mfranco.backendhelpers.co";

  return (
    <>
    <Author lang="en"  title={messages.title} subTitle={messages.subTitle} description={description} available={available} url={url}/>
    </>
  )
}



