import React from "react"
import { Link } from "gatsby"

import Blog from '../components/blog/en'
import LeftColumn from '../components/blog/left-column/en'
import RightColumn from '../components/blog/right-column/en'
import PostSummary from '../components/blog/post-summary/en'
import PostIndex from '../components/blog/post-index/en'
import Author from '../components/blog/author/en'


export default (props) => {
  return (
  <Blog {...props}>

    <LeftColumn {...props} >
      <Author {...props} />

    </LeftColumn>

    <RightColumn {...props}>
      <PostIndex />
    </RightColumn>

  </Blog>
)}

